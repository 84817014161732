<template>
  <div class="work-statistics">
    <!-- start 条件过滤区域 -->
    <div class="df-row">
      <!-- 日期选择器 -->
      <div class="df-col">
        <el-date-picker
          style="width: 250px;"
          v-model="dayArray"
          type="daterange"
          size="mini"
          value-format="yyyy-MM-dd"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          @change="onChangeDate"
        >
        </el-date-picker>
      </div>

      <div class="df-col" style="width: 120px;">
        <!-- 职位选择器 -->
        <el-select
          v-model="submitData.duty_no"
          size="mini"
          @change="handleLevelAndEmpl(1)"
        >
          <el-option
            v-for="item in dutyList"
            :key="item.DutyNo"
            :label="item.DutyName"
            :value="item.DutyNo"
          >
          </el-option>
        </el-select>
      </div>

      <div class="df-col" style="width: 140px;">
        <!-- 级别选择器 -->
        <el-select
          v-model="submitData.level_guid"
          size="mini"
          @change="handleLevelAndEmpl(2)"
        >
          <el-option
            v-for="item in levelList"
            :key="item.LevelGuid"
            :label="item.LevelName"
            :value="item.LevelGuid"
          >
          </el-option>
        </el-select>
      </div>

      <div class="df-col" style="width: 120px;">
        <!-- 员工选择器 -->
        <el-select
          v-model="submitData.empl_guid"
          size="mini"
          @change="handleLevelAndEmpl(3)"
        >
          <el-option
            v-for="item in emplList"
            :key="item.EmplGuid"
            :label="item.EmplName"
            :value="item.EmplGuid"
          >
          </el-option>
        </el-select>
      </div>

      <div class="df-col">
        <!-- 查询按钮 -->
        <el-button type="primary" size="mini" @click="onSearch">
          查询
        </el-button>
      </div>

      <div class="df-col">
        <!-- 导出表格按钮 -->
        <el-button
          type="primary"
          size="mini"
          :disabled="excelDisabled"
          @click="onExportExcel"
          >导出表格</el-button
        >
      </div>
    </div>
    <!-- end 条件过滤区域 -->

    <div class="print">
      <el-table
        id="exprot-table"
        size="mini"
        :data="dataList"
        :max-height="tableMaxHeight"
        border
        stripe
      >
        <el-table-column
          v-for="(headItem, headIndex) in headList"
          :key="headIndex"
          :prop="headItem.DataKey"
          :label="headItem.Name"
        >
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import Attendance from "@/api/attendance.js";

export default {
  data() {
    return {
      tableMaxHeight: "",
      submitData: {
        // 提交数据
        begin_date: "",
        end_date: "",
        duty_no: "",
        level_guid: "",
        empl_guid: "",
      },
      dayArray: [], // 日期范围选择数据
      dutyList: [], // 职位列表
      copyEmplList: [], // 级别列表(源数据)
      copyLevelList: [], // 员工列表(源数据)
      levelList: [], // 级别列表(回显数据)
      emplList: [], // 员工列表(回显数据)
      dataList: [], // 考勤统计列表
      headList: [], // 表格的表头（二级表头）
      topList: [], // 表格的合并表头（一级表头）
      attenGuids: [], // 考勤id
      excelDisabled: false, // 导出防抖
    };
  },

  created() {
    this.getResize();
  },

  mounted() {
    window.addEventListener("resize", this.getResize);

    this.dayArray = [
      this.util.getNowMonthToday(),
      this.util.getNowMonthToday(),
    ];
    this.onChangeDate(this.dayArray);
    this.initAtteQuery();
  },

  destroyed() {
    window.removeEventListener("resize", this.getResize);
  },

  methods: {
    // 初始化考勤明细
    async initAtteQuery() {
      try {
        let { data } = await Attendance.initAtteQuery();
        let { dutyList, emplList, levelList } = data;

        this.dutyList = [{ DutyName: "全部职位", DutyNo: "" }, ...dutyList];
        this.copyEmplList = [
          { EmplName: "全部员工", EmplGuid: "", EmplNo: "", LevelGuid: "" },
          ...emplList,
        ];
        this.copyLevelList = [
          { LevelName: "全部级别", LevelGuid: "", DutyNo: "" },
          ...levelList,
        ];

        await this.$nextTick(() => {
          this.handleLevelAndEmpl();
        });
      } catch (err) {
        console.log(err);
      }
    },

    // 获取考勤统计数据
    async getStatisData() {
      try {
        let { data } = await Attendance.getStatisData(this.submitData);
        let { DataList, HeadList, TopList } = data;
        this.dataList = DataList;
        this.headList = HeadList;
        this.topList = TopList;
      } catch (err) {
        console.log(err);
      }
    },

    // 查询按钮点击事件
    onSearch() {
      this.getStatisData();
    },

    // 获取浏览器窗口大小
    getResize() {
      let { clientHeight } = this.util.getDocumentElementInfo();
      this.tableMaxHeight = clientHeight - (100 + 40 + 72);
    },

    // 级别列表和员工列表处理事件
    handleLevelAndEmpl(changeType) {
      let copyEmplList = JSON.parse(JSON.stringify(this.copyEmplList)),
        copyLevelList = JSON.parse(JSON.stringify(this.copyLevelList));
      let { duty_no, level_guid, empl_guid } = this.submitData;

      if (changeType == 1) {
        level_guid = "";
        empl_guid = "";
      } else if (changeType == 2) {
        empl_guid = "";
      }

      let levelArr = !duty_no
        ? copyLevelList
        : copyLevelList.filter((item, index) => {
            if (index == 0 || item.DutyNo == duty_no) {
              return item;
            }
          });

      let emplArr = !duty_no
        ? copyEmplList
        : copyEmplList.filter((item, index) => {
            if (index == 0 || item.LevelGuid == level_guid) {
              return item;
            }
          });

      this.submitData.level_guid = level_guid;
      this.submitData.empl_guid = empl_guid;

      this.levelList = levelArr;
      this.emplList = emplArr;
    },

    // 时间改变事件
    onChangeDate(event) {
      this.submitData.begin_date = event[0];
      this.submitData.end_date = event[1];
    },

    // 导出表格
    onExportExcel() {
      this.excelDisabled = true;
      this.isFixedRow = false;
      this.$nextTick(() => {
        try {
          this.util.onExportExcel("#exprot-table", "员工考勤统计表");
        } finally {
          this.excelDisabled = false;
          this.isFixedRow = "right";
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.work-statistics {
  .df-row {
    margin-top: 20px;
  }
}
</style>
